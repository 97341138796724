import { useContext, useEffect } from 'react';

import { LoaderContext } from '@/contexts/LoaderContextProvider';

const useLoader = () => {
  const { show } = useContext(LoaderContext);

  useEffect(() => {
    show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoader;
