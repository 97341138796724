const MultiversXLogo = (
  props: JSX.IntrinsicAttributes &
    React.SVGProps<SVGSVGElement> & { xColor?: string }
) => {
  const { color = '#1F2226', xColor = '#1EEBC6', ...rest } = props;
  return (
    <>
      <svg
        width='275'
        height='41.25'
        viewBox='0 0 275 41.25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...rest}
      >
        <g clipPath='url(#clip0_4573_3020)'>
          <path d='M122.048 0V4.88959H116.336V0H122.048Z' fill={color} />
          <path
            d='M5.66248 12.6279V15.2783C5.66248 23.2429 5.64915 31.2057 5.68152 39.1684C5.68152 40.0443 5.44732 40.3375 4.56765 40.2803C3.4614 40.2079 2.34753 40.2365 1.23748 40.2803C0.649128 40.2975 0.424451 40.067 0.47586 39.5035C0.491092 39.3455 0.47586 39.1855 0.47586 39.0275V7.80687C0.47586 6.51782 0.47586 6.51782 1.75918 6.51782C3.82127 6.51782 5.88525 6.54638 7.94544 6.50069C8.65943 6.48545 8.99645 6.72156 9.27825 7.38988C12.4606 15.133 15.6625 22.8666 18.8841 30.5906C19.0879 31.0762 19.3183 31.5427 19.621 32.2167C20.1408 31 20.5902 29.9699 21.0243 28.9322C24.0327 21.7375 27.0347 14.5389 30.0304 7.33654C30.2704 6.76535 30.5388 6.49307 31.1995 6.50449C33.5453 6.54448 35.8911 6.51973 38.3549 6.51973C38.3777 6.90053 38.4159 7.23754 38.4159 7.57268C38.4159 18.1363 38.4228 28.7006 38.4368 39.2655C38.4368 40.05 38.2254 40.3222 37.4257 40.2879C36.0358 40.2289 34.644 40.2708 33.1645 40.2708V12.4242L32.9379 12.3461C32.8122 12.5974 32.6695 12.8412 32.5571 13.1077C28.9343 21.832 25.3167 30.5608 21.7041 39.294C21.3728 40.0975 21.0071 40.4631 20.0704 40.4365C17.3724 40.3698 17.3781 40.4155 16.3785 37.9974C12.9842 29.7605 9.58545 21.5261 6.18228 13.2943C6.08708 13.0639 5.97284 12.8431 5.86621 12.6184L5.66248 12.6279Z'
            fill={color}
          />
          <path
            d='M187.449 26.084H162.95C162.947 28.3013 163.706 30.4524 165.1 32.177C169.02 37.1522 177.324 37.1522 181.304 32.2226C181.469 32.0189 181.735 31.7714 181.958 31.7657C183.599 31.7276 185.242 31.7447 186.983 31.7447C186.638 33.4051 185.851 34.6922 184.871 35.8613C182.137 39.1267 178.525 40.6214 174.363 40.8765C167.82 41.2783 162.611 38.9725 159.275 33.1461C155.01 25.7032 157.988 15.3471 165.389 11.5371C172.904 7.66997 184.445 10.2862 186.939 22.0093C187.213 23.3022 187.276 24.6331 187.449 26.084ZM181.166 21.4515C180.324 17.6815 177.046 14.5436 172.31 14.5817C167.761 14.6197 164.035 17.4035 163.34 21.4515H181.166Z'
            fill={color}
          />
          <path
            d='M212.797 31.9528C214.669 31.9528 216.415 31.9413 218.151 31.968C218.27 31.9797 218.384 32.016 218.488 32.0747C218.592 32.1334 218.682 32.2132 218.753 32.3088C220.254 34.6756 222.561 35.778 225.194 36.0294C227.098 36.2102 229.046 36.0427 230.956 35.839C231.792 35.7494 232.653 35.2848 233.367 34.7955C235.562 33.2894 235.423 30.024 233.003 28.9406C231.508 28.2741 229.777 28.0971 228.132 27.8172C225.48 27.364 222.757 27.2155 220.158 26.5605C215.44 25.3743 212.982 21.4539 213.822 16.8804C214.263 14.4717 215.671 12.7562 217.777 11.5757C220.992 9.77446 224.49 9.62596 228.041 9.88301C231.171 10.1115 234.094 10.9626 236.563 13.0075C238.139 14.2732 239.293 15.9876 239.873 17.9238C237.957 17.9238 236.16 17.9447 234.363 17.9009C234.105 17.9009 233.824 17.6077 233.601 17.3983C230.68 14.5727 225.168 13.5711 221.426 15.2219C220.073 15.8198 219.06 16.7071 219.094 18.3617C219.126 19.864 219.856 20.9417 221.569 21.4596C222.982 21.8842 224.475 22.0803 225.948 22.2688C228.9 22.6496 231.875 22.8095 234.738 23.7921C241.158 25.9798 241.636 33.8569 238.336 37.2994C236.529 39.1843 234.288 40.222 231.763 40.6638C227.289 41.4464 222.902 41.2997 218.785 39.1196C216.213 37.7563 214.265 35.7685 213.132 33.0457C213.013 32.7239 212.934 32.3926 212.797 31.9528Z'
            fill={color}
          />
          <path
            d='M45.3068 10.6092H50.7448V11.6431C50.7448 16.4965 50.7276 21.3537 50.7448 26.2014C50.7448 28.591 51.1256 30.9083 52.6298 32.8503C54.6938 35.516 57.5155 36.2662 60.7048 35.6417C63.9645 35.0019 66.0171 32.9189 66.9425 29.7829C67.2456 28.6829 67.3949 27.5462 67.3861 26.4051C67.4344 21.4915 67.4344 16.5758 67.3861 11.6583C67.3766 10.7482 67.6698 10.5159 68.5171 10.5597C69.9299 10.6206 71.3484 10.5769 72.866 10.5769V11.7497C72.866 20.8511 72.8529 29.9543 72.887 39.0575C72.887 39.9943 72.6851 40.3561 71.6817 40.2876C70.3012 40.1962 68.9094 40.2647 67.4433 40.2647V35.2551C67.2846 35.361 67.1378 35.4835 67.0053 35.6207C64.1721 39.8915 60.1793 41.5594 55.2003 40.6627C50.3183 39.7868 47.2851 36.7289 45.9047 32.0544C45.5138 30.6495 45.3153 29.1981 45.3144 27.7399C45.2459 22.2182 45.284 16.6964 45.284 11.1747C45.2725 11.0281 45.2897 10.8739 45.3068 10.6092Z'
            fill={color}
          />
          <path
            d='M100.545 3.10137V10.5728H101.656C104.893 10.5728 108.13 10.5938 111.367 10.5576C112.129 10.5576 112.448 10.7195 112.405 11.5496C112.338 12.8406 112.388 14.1353 112.388 15.5252H100.629C100.594 15.8851 100.548 16.1555 100.548 16.4259C100.548 20.708 100.527 24.9941 100.558 29.2725C100.59 33.5985 102.186 35.1445 106.537 35.1502H112.315V40.1008C112.251 40.1464 112.201 40.2112 112.152 40.2112C108.915 40.1445 105.666 40.3178 102.473 39.9427C98.126 39.4343 95.6018 36.5669 95.2226 32.18C95.0554 30.2264 95.0952 28.2519 95.0836 26.2888C95.0663 23.0843 95.0836 19.8817 95.0836 16.6772C95.0836 16.3383 95.0554 15.9974 95.0359 15.5595C93.1775 15.5595 91.3784 15.5291 89.5808 15.5747C88.8383 15.5957 88.5625 15.3843 88.6059 14.6227C88.6667 13.547 88.6537 12.4654 88.6059 11.3858C88.5813 10.7499 88.8325 10.5633 89.436 10.5728C91.2698 10.5976 93.1051 10.5728 95.0742 10.5728C95.0742 8.84208 95.0742 7.20459 95.0742 5.56901C95.0742 3.09375 95.0742 3.09375 97.5492 3.09375L100.545 3.10137Z'
            fill={color}
          />
          <path
            d='M141.77 33.2593C142.611 31.3668 143.4 29.6093 144.177 27.8481C146.578 22.393 148.988 16.9436 151.361 11.4733C151.654 10.7955 151.991 10.5098 152.758 10.546C154.239 10.6146 155.729 10.565 157.391 10.565C157.069 11.3267 156.819 11.9626 156.54 12.5814C152.465 21.5939 148.394 30.6064 144.327 39.6189C143.933 40.5005 143.482 40.8946 142.496 40.788C141.509 40.6813 140.251 41.0812 139.521 40.6185C138.792 40.1558 138.556 38.9049 138.133 37.9909C134.029 29.1054 129.929 20.2103 125.833 11.3057C125.746 11.1153 125.694 10.9021 125.586 10.5841C127.532 10.5841 129.394 10.5498 131.258 10.6222C131.519 10.6317 131.848 11.1192 131.996 11.4524C133.864 15.6127 135.702 19.8016 137.551 23.9714C138.81 26.8097 140.073 29.6474 141.34 32.4844C141.439 32.7072 141.576 32.911 141.77 33.2593Z'
            fill={color}
          />
          <path
            d='M192.623 10.6339H197.99V15.3788C198.16 15.356 198.251 15.3674 198.268 15.3388C200.288 11.765 203.6 10.5787 207.44 10.3731C208.516 10.3159 209.597 10.3731 210.677 10.3445C211.248 10.3293 211.471 10.5349 211.459 11.1194C211.431 12.4523 211.427 13.7851 211.459 15.1179C211.473 15.7311 211.193 15.8396 210.654 15.8605C208.47 15.9405 206.275 15.9081 204.108 16.2071C199.978 16.7783 198.057 19.0822 198.036 23.2521C198.009 28.5834 198.009 33.9147 198.036 39.246C198.036 40.0209 197.846 40.3123 197.035 40.2799C195.584 40.2228 194.13 40.2628 192.617 40.2628L192.623 10.6339Z'
            fill={color}
          />
          <path
            d='M79.7109 1.54688H85.0271V40.1304H79.7109V1.54688Z'
            fill={color}
          />
          <path
            d='M116.57 40.1555V10.6523H121.901V40.1651L116.57 40.1555Z'
            fill={color}
          />
          <path
            d='M245.789 21.4385L258.927 14.3935L245.803 7.34854C246.48 6.03474 247.099 4.76665 247.805 3.54044C247.899 3.3805 248.483 3.36717 248.758 3.47761C252.415 4.92087 256.05 6.41936 259.711 7.85693C260.204 8.03112 260.742 8.03112 261.235 7.85693C264.927 6.41745 268.6 4.92658 272.278 3.43191C272.72 3.25103 273.04 3.16915 273.289 3.68324C273.88 4.87137 274.498 6.04617 275.171 7.34854L262.042 14.3935L275.18 21.4385C274.502 22.7428 273.877 24.0127 273.165 25.2294C273.072 25.3875 272.48 25.3855 272.191 25.2732C269.474 24.2126 266.772 23.1102 264.066 22.023C263.823 21.9512 263.587 21.8582 263.359 21.745C261.425 20.5169 259.59 20.7454 257.558 21.7164C254.761 23.0493 251.809 24.068 248.941 25.2618C248.215 25.5626 247.798 25.4807 247.464 24.7173C246.962 23.6262 246.372 22.5809 245.789 21.4385Z'
            fill={xColor}
          />
        </g>
        <defs>
          <clipPath id='clip0_4573_3020'>
            <rect
              width='275'
              height='41.25'
              fill='white'
              transform='translate(0.3125)'
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default MultiversXLogo;
